<template>
  <b-row>
    <b-colxx>
      <div class="title-and-right-element">
        <PageTitle title="Quick Add Lead"></PageTitle>
      </div>
      <b-card class="mb-4 mr-8">
        <b-form
          @submit.stop.prevent="onValidateFormSubmit"
          class="av-tooltip tooltip-label-right"
        >
          <confirmation ref="Confirmation"></confirmation>
          <!-- Demographic start -->
          <h3 class="subtitle">Demographic</h3>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Company Legal Name">
                <b-form-input
                  v-model.trim="$v.company.companyLegalName.$model"
                  :state="!$v.company.companyLegalName.$error"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="!$v.company.companyLegalName.maxLengthValue"
                >
                  Must be fewer than 75 characters.
                </div>
                <div
                  class="text-danger"
                  v-if="
                    !$v.company.companyLegalName.required &&
                      $v.company.companyLegalName.$dirty
                  "
                >
                  This field is required.
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Company DBA Name">
                <b-form-input
                  v-model.trim="$v.company.dbaCompanyName.$model"
                  :state="!$v.company.dbaCompanyName.$error"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="!$v.company.dbaCompanyName.maxLengthValue"
                >
                  Must be fewer than 75 characters.
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Website">
                <b-form-input
                  v-model.trim="$v.company.website.$model"
                  :state="!$v.company.website.$error"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="!$v.company.website.maxLengthValue"
                >
                  Must be fewer than 255 characters.
                </div>
                <div class="text-danger" v-if="!$v.company.website.url && $v.company.website.$dirty"> Must be a valid URL.</div>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Sales Member">
                <multiselect
                  v-model="company.salesMemberID"
                  :options="employees"
                  track-by="employeeID"
                  label="displayName"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Program(s) of Interest">
                <multiselect
                  v-model="company.programsOfInterest"
                  :options="groupedPrograms"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Programs..."
                  group-values="options"
                  group-label="label"
                  track-by="programID"
                  label="dropdownLabel"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Number of Locations">
                <b-form-input v-model.trim="company.numberofLocations" />
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="4">
              <b-form-group label="Lead Type">
                <multiselect
                  v-model="company.leadTypeID"
                  :options="leadTypes"
                  track-by="id"
                  label="description"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx v-if="isLeadTypeOtherVisible" lg="2">
              <b-form-group label="Other">
                <b-form-input type="text" v-model="company.leadTypeOther" />
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group label="Business Line(s)">
                <multiselect
                  v-model="company.businessLines"
                  :options="businessLines"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Business Lines..."
                  track-by="id"
                  label="description"
                ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Demographic End -->
          <!-- Address info Start -->
          <h3 id="address-title" class="subtitle">Company Address</h3>
          <b-row>
            <b-col>
              <b-form-group
                label="Street Address 1:"
                label-for="street-address-1"
              >
                <vue-google-autocomplete
                  id="map"
                  ref="autocomplete"
                  classname="form-control"
                  placeholder="Search Here..."
                  v-on:placechanged="getAddressData"
                  v-on:change="handleAddressChange"
                  country="us">
                </vue-google-autocomplete>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Street Address 2:"
                label-for="street-address-2"
              >
                <b-form-input
                  id="street-address-2"
                  class="col-sm-10"
                  v-model="company.address.addressLine2"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group label="City:" label-for="city">
                <b-form-input
                  id="city"
                  class="col-sm-10"
                  v-model="company.address.city"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <label>State</label>
                <b-form-select
                  v-model.trim="company.address.stateID"
                  :options="states"
                  value-field="stateID"
                  text-field="stateCode"
                  plain
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <label>Zip Code</label>
                <b-form-input v-model="company.address.zip"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Address info End -->
          <!--Contact Info start -->
          <h3 id="contact-title" class="subtitle">Contact Info</h3>
          <b-row>
            <b-col lg="3">
              <b-form-group label="Salutation:" label-for="nested-salutation">
                <b-form-input
                  id="nested-salutation"
                  class="col-sm-10"
                  v-model="company.contact.salutation"
                  placeholder="Salutation"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="First Name:" label-for="nested-firstName">
                <b-form-input
                  id="nested-firstName"
                  class="col-sm-10"
                  v-model.trim="$v.company.contact.firstName.$model"
                  :state="!$v.company.contact.firstName.$error"
                  placeholder="First Name"
                ></b-form-input>
                <!-- <div
                  class="text-danger"
                  :state="!$v.company.contact.firstName.$error"
                  v-if="!$v.company.contact.firstName.alpha"
                >
                  First Name must be composed only with letters!
                </div> -->
                <div
                  class="text-danger"
                  :state="!$v.company.contact.firstName.$error"
                  v-if="!$v.company.contact.firstName.maxLength"
                >
                  Maximum Characters Allowed 50.
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <b-form-group label="Last Name:" label-for="nested-lastName">
                <b-form-input
                  id="nested-lastName"
                  class="col-sm-10"
                  v-model.trim="$v.company.contact.lastName.$model"
                  :state="!$v.company.contact.lastName.$error"
                  placeholder="Last Name"
                ></b-form-input>

                <!-- <div
                  class="text-danger"
                  :state="!$v.company.contact.lastName.$error"
                  v-if="!$v.company.contact.lastName.alpha"
                >
                  Last Name must be composed only with letters!
                </div> -->
                <div
                  class="text-danger"
                  :state="!$v.company.contact.lastName.$error"
                  v-if="!$v.company.contact.lastName.maxLength"
                >
                  Maximum Characters Allowed 50.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Title:" label-for="nested-title">
                <b-form-input
                  id="nested-title"
                  class="col-sm-10"
                  v-model.trim="$v.company.contact.title.$model"
                  :state="!$v.company.contact.title.$error"
                  placeholder="Title"
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.company.contact.title.$error"
                  v-if="!$v.company.contact.title.maxLength"
                >
                  Maximum Characters Allowed 50.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Email:" label-for="nested-emailaddress">
                <b-form-input
                  id="nested-emailaddress"
                  class="col-sm-6"
                  v-model.trim="$v.company.contact.emailAddress.$model"
                  :state="!$v.company.contact.emailAddress.$error"
                  placeholder="Email"
                ></b-form-input>

                <div
                  class="text-danger"
                  :state="!$v.company.contact.emailAddress.$error"
                  v-if="!$v.company.contact.emailAddress.email"
                >
                  Enter a valid email address.
                </div>
                <div
                  class="text-danger"
                  :state="!$v.company.contact.emailAddress.$error"
                  v-else-if="!$v.company.contact.emailAddress.maxLength"
                >
                  Maximum Characters Allowed 50.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Contact Type(s):" label-for="nested-contacttypes">
                  <multiselect
                    v-model="company.contact.contactTypes"
                    :options="contactTypeOptions"
                    group-values="options"
                    group-label="label"
                    track-by="id"
                    label="description"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Contact Type(s)..."
                    :class="{ 'multiselect-is-invalid' : $v.company.contact.contactTypes.$error }"
                  >
                    <template slot="option" slot-scope="props">
                      <div :title="props.option.tooltip">
                        {{ getOptionLabel(props) }}
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
            </b-col>
            <b-col lg="4" v-show="company.contact.contactTypes.find(ct => ct.id === 15) !== undefined">
              <b-form-group label="Consultant">
                  <b-form-select
                    plain
                    v-model="company.contact.affiliateID"
                    :state="!$v.company.contact.affiliateID.$error"
                    :options="consultants"
                    @input="$v.company.contact.affiliateID.$touch"
                  ></b-form-select>
                  <div
                    class="text-danger"
                    :state="!$v.company.contact.affiliateID.$error"
                    v-if="!$v.company.contact.affiliateID.required"
                  >
                    Consultant is required.
                  </div>
                </b-form-group>
            </b-col>
            <b-col lg="4" v-show="company.contact.affiliateID === -1">
                <b-form-group label="Other" >
                  <b-form-input
                    type="text"
                    v-model="company.contact.consultantOther"
                    @input="$v.company.contact.consultantOther.$touch"
                    :state="!$v.company.contact.consultantOther.$error"
                  />
                  <div
                    class="text-danger"
                    :state="!$v.company.contact.consultantOther.$error"
                    v-if="!$v.company.contact.consultantOther.required"
                  >
                    Other Consultant is required.
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.company.contact.consultantOther.$error"
                    v-else-if="!$v.company.contact.consultantOther.minLength"
                  >
                    Minimum Characters Allowed 2.
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.company.contact.consultantOther.$error"
                    v-else-if="!$v.company.contact.consultantOther.maxLength"
                  >
                    Maximum Characters Allowed 50.
                  </div>
                </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Notes:" label-for="nested-notes">
                <b-form-textarea
                  id="nested-notes"
                  class="col-sm-8"
                  rows="3"
                  v-model.trim="company.notes"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Phone Number:"
                label-for="nested-phoneNumber"
              >
                <b-form-input
                  v-mask="phoneMask"
                  id="nested-phoneNumber"
                  class="col-sm-10"
                  v-model.trim="$v.company.phone.phoneNumber.$model"
                  :state="!$v.company.phone.phoneNumber.$error"
                  placeholder="Phone Number"
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.company.phone.phoneNumber.$error"
                  v-if="!$v.company.phone.phoneNumber.minLength"
                >
                  Must have at least 10 digits.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Ext."
                label-for="nested-phoneNumberExtension"
              >
                <b-form-input
                  id="nested-phoneNumberExtension"
                  v-mask="extMask"
                  class="col-sm-10"
                  v-model.trim="$v.company.phone.phoneNumberExtension.$model"
                  :state="!$v.company.phone.phoneNumberExtension.$error"
                  placeholder="Ext."
                ></b-form-input>
                <div
                  class="text-danger"
                  :state="!$v.company.phone.phoneNumberExtension.$error"
                  v-if="!$v.company.phone.phoneNumberExtension.maxLength"
                >
                  Must have at most
                  {{
                    $v.company.phone.phoneNumberExtension.$params.maxLength.max
                  }}
                  digits.
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <label>Phone Number Type</label>

                <b-form-select
                  v-model.trim="company.phone.phoneNumberType"
                  :options="phoneTypes"
                  value-field="phoneNumberTypeID"
                  text-field="phoneNumberTypeDesc"
                  plain
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Button -->
          <b-row>
            <b-colxx xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-colxx>
            <b-colxx lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.save') }}
              </b-button>
            </b-colxx>
          </b-row>
        </b-form>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<style lang="scss">
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}

.ratioTag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}
.closedLost {
  background-color: #ffffff;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
.coldLead {
  background-color: #5dade2;
  color: #ffffff;
}
.lead {
  background-color: #52be80;
  color: #ffffff;
}
.warmLead {
  background-color: #eb984e;
  color: #ffffff;
}
.hotLead {
  background-color: #ec7063;
  color: #ffffff;
}
.closedWon {
  background-color: #ffffff;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
.subtitle {
  margin-bottom: 10px;
}
#contact-title {
  margin-top: 20px;
}
#address-title {
  margin-top: 20px;
}

</style>

<script>
import { validationMixin } from 'vuelidate'
import Switches from 'vue-switches'
import Multiselect from 'vue-multiselect'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import CompanyAddressMixin from '../../../mixins/CompanyAddressMixin.vue'
import moment from 'moment/moment.js'
import confirmation from '../../../components/Common/Confirmation.vue'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import PhoneMixin from '../../../mixins/PhoneMixin.vue'
import DirtyDataMixin from '../../../mixins/DirtyDataMixin.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapMutations } from 'vuex'
import CompanyContactMixin from '../../../mixins/CompanyContactMixin.vue'
import ProgramMixin from '../../../mixins/ProgramMixin.vue'

const {
  required,
  maxLength,
  email,
  // alpha,
  requiredIf,
  minLength,
  helpers
} = require('vuelidate/lib/validators')

const url = helpers.regex(
  'url',
  /^((http|ftp|https):\/\/)?(www\.)?([\w-]+\.)+(com\b|edu\b|biz\b|gov\b|in(?:t|fo)\b|mil\b|net\b|org\b|[a-z][a-z]\b)(\/[\w\- ./?]*)?$/
)

export default {
  components: {
    // switches: Switches,
    multiselect: Multiselect,
    Confirmation: confirmation,
    PageTitle: ParentEntityPageTitle,
    'vue-google-autocomplete': VueGoogleAutocomplete
  },
  props: ['companyID'],
  data () {
    return {
      // title: 'Lead'
    }
  },
  async created () {
    this.company = {
      companyLegalName: '',
      dbaCompanyName: null,
      website: null,
      notes: null,
      numberofLocations: null,
      programsOfInterest: [],
      leadTypeID: null,
      salesMemberID: null,
      leadTypeOther: '',
      businessLines: null,
      contact: {
        firstName: '',
        lastName: '',
        title: '',
        emailAddress: '',
        salutation: '',
        contactTypes: [],
        affiliateID: null,
        consultantOther: null
      },
      phone: {
        phoneNumber: '',
        phoneNumberExtension: '',
        phoneNumberType: undefined
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateID: undefined,
        zip: undefined
      }
    }
    this.initDirtyDataWatcher('company')
    await this.getLeadTypes()
    await this.getPhoneTypes()
    await this.getStates()
    await this.getTimeframes()
    await this.getBusinessLines()
    await this.getAllProgramsByType()
    await this.getAllSalesMembers()
    await this.getLeadReferralTypes()
    await this.getUSStates()
    await this.getContactTypeOptions()
    await this.getAllCertifiedConsultants()
    this.mapConsultants()
  },
  mixins: [
    validationMixin,
    CompanyMixin,
    CompanyAddressMixin,
    AffiliateMixin,
    PhoneMixin,
    DirtyDataMixin,
    CompanyContactMixin,
    ProgramMixin
  ],
  validations: {
    company: {
      companyLegalName: {
        maxLengthValue: maxLength(75),
        required
      },
      dbaCompanyName: {
        maxLengthValue: maxLength(75)
      },
      website: {
        maxLengthValue: maxLength(255),
        url
      },
      contact: {
        firstName: {
          // alpha,
          maxLength: maxLength(50)
        },
        lastName: {
          // alpha,
          maxLength: maxLength(50)
        },
        emailAddress: {
          email,
          maxLength: maxLength(50)
        },
        title: {
          maxLength: maxLength(50)
        },
        contactTypes: {

        },
        affiliateID: {
          required: requiredIf(function () {
            return this.company.contact.contactTypes.find(ct => ct.id === 15) !== undefined
          })
        },
        consultantOther: {
          required: requiredIf(function () {
            if (this.company.contact.affiliateID === null) {
              return false
            }

            return this.company.contact.affiliateID === -1
          }),
          minLength: minLength(2),
          maxLength: maxLength(300)
        }
      },

      phone: {
        phoneNumber: {
          minLength: minLength(14)
        },
        phoneNumberExtension: {
          maxLength: maxLength(6)
        }
      }
    }
  },
  methods: {
    clearData () {
      this.company = {
        companyLegalName: '',
        dbaCompanyName: null,
        website: null,
        notes: null,
        numberofLocations: null,
        programsOfInterest: [],
        leadTypeID: null,
        salesMemberID: null,
        leadTypeOther: '',
        businessLines: null,
        contact: {
          firstName: '',
          lastName: '',
          title: '',
          emailAddress: '',
          salutation: '',
          contactTypes: [],
          affiliateID: null,
          consultantOther: null
        },
        phone: {
          phoneNumber: '',
          phoneNumberExtension: '',
          phoneNumberType: undefined
        },
        address: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          stateID: undefined,
          zip: undefined
        }
      }
    },
    ...mapMutations(['setPreserveMessageAfterPush']),
    async onValidateFormSubmit () {
      this.$v.company.$touch()
      if (!this.$v.company.$error) {
        const quickAddNewCompanyResult = await this.quickAddNewCompany(this.company)

        if (quickAddNewCompanyResult.status === 200) {
          const companyId = quickAddNewCompanyResult.data.companyID

          this.setDirtyData(false)
          this.clearData()
          this.setPreserveMessageAfterPush(true)
          this.$router.push({ name: 'leads' })
          this.setInfoMessage(
              `Company ${companyId} successfully created!`
          )
        }
      } else {
        this.setDangerMessage('Form must be filled out properly.')
      }
    },
    cancelForm () {
      this.$router.push({ name: 'leads' })
    },
    getAddressData: function (addressData) {
      const { street_number: streetNumber = '', route = '', locality = '', administrative_area_level_1: stateName = '', postal_code: postalCode = '' } = addressData
      const stateId = this.states.find(({ stateCode }) => stateCode === stateName).stateID || ''
      this.company = {
        ...this.company,
        address: {
          addressLine1: `${streetNumber} ${route}`,
          addressLine2: '',
          city: locality,
          stateID: stateId,
          zip: postalCode
        }
      }
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`)
      }, 500)
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update('')
    },
    getOptionLabel (props) {
      if (props.option.description === undefined) {
        return props.option.$groupLabel
      }
      return props.option.description
    },
    mapConsultants () {
      this.consultants = this.consultants.map((c) => {
        return {
          text: c.companyName,
          value: c.affiliateID
        }
      })

      // Pierson 9/15/21 - I added the push in here instead of in the API
      // so that it would keep the API agnostic and not mess up other GetAll calls
      this.consultants.push({
        value: -1,
        text: 'Other'
      })
    }
  },
  computed: {
    isLeadTypeOtherVisible: {
      get: function () {
        if (
          this.company.leadTypeID === null ||
          this.company.leadTypeID === undefined
        ) {
          return false
        }

        return this.company.leadTypeID.id === 6
      }
    }
  }
}
</script>
